@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';


.block-banner-section-wrap {
  position: relative;
  .video-background {
    @include absolutefull;
    z-index: -1;
    border-radius: 0px 0px 30px 30px;
    overflow: hidden;
    &::after {
      content: '';
      @include absolutefull;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }
    img {
      @include objetfit;
    }
    .youtube-video-wrap {
      @include absolutefull;
      display: flex;
      align-items: center;
      overflow: hidden;
      .youtube-video {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
      }
      .player {
        width: 100%;
        height: 100%;
      }
    }
  }
  .banner-content {
    padding: 100px 0;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    h1 {
      color: $white;
      margin-bottom: 24px;
      span {
        position: relative;
        display: inline-block;
        &::before {
          content: '';
          position: absolute;
          left: -20px;
          top: -10px;
          right: -20px;
          bottom: -10px;
          background-image: url('./../../assets/img/isolation_mode.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          z-index: -1;
        }
      }
    }
    p {
      color: $white;
      margin-bottom: 32px;
    }
  }
  @include media-breakpoint-up(sm) { 
    
  }
  @include media-breakpoint-up(md) { 
    .banner-content {
      padding: 260px 0 160px;
      h1 {
        margin-bottom: 48px;
      }
      p {
        font-size: toRem(18);
      }
    }
  }
}
